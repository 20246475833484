.button {
    padding: 0.75em 1.2em;
    min-height: rem-calc(50);
    line-height: 1.25em;
    font-size: rem-calc(20);
    border-radius: 30px;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 900;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        vertical-align: middle;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--large {
        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 100px;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "brand", "secondary"), 10%);
            }
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
            }
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "secondary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }
}