﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-size: rem-calc-sm(40);
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    margin-top: .75em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(84);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(90);
    }
}

h2, .h2 {
    font-size: rem-calc-sm(30);
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(42);
    }
}

h3, .h3 {
    font-size: rem-calc-sm(24);
    font-weight: 700;
    line-height: 2.5em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(36);
    }
}

h4, .h4 {
    font-size: rem-calc-sm(22);
    font-weight: 700;
    line-height: 2.5em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(28);
    }
}

h5, .h5 {
    font-size: rem-calc-sm(20);
    font-weight: 700;
    line-height: 2.5em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24);
    }
}

h6, .h6 {
    font-size: rem-calc-sm(18);
    font-weight: 700;
    line-height: 2.5em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
    letter-spacing: 0.3em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
    }
}