.slider {
    position: relative;

    &::after {
        content: "";
        display: block;
        z-index: -1;
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 0;
        width: 100%;
        background: map-deep-get($colors, "white", "default");
    }

    &__inner {
        position: relative;
        width: 120%;
        height: 230px;
        margin-right: -20%;

        @include media-breakpoint-up(md) {
            height: 460px;
        }

        @include media-breakpoint-up(lg) {
            height: 690px;
        }
    }

    &__slides {
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 0;
        border: none;
        padding: 10px 8px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background: map-deep-get($colors, "white", "default");

        svg {
            display: block;
            margin: 0;
            padding: 0;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__button--previous {
        transform: translate(-50%, -100%);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__button--next {
        transform: translate(-50%, 0);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
}