@font-face {
    font-family: GTWalsheim;
    src: url("../fonts/GTWalsheim-RegularOblique.eot");
    src: url("../fonts/GTWalsheim-RegularOblique.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-RegularOblique.woff2") format("woff2"),
        url("../fonts/GTWalsheim-RegularOblique.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: GTWalsheim;
    src: url("../fonts/GTWalsheim-Regular.eot");
    src: url("../fonts/GTWalsheim-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-Regular.woff2") format("woff2"),
        url("../fonts/GTWalsheim-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: GTWalsheim;
    src: url("../fonts/GTWalsheim-Bold.eot");
    src: url("../fonts/GTWalsheim-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-Bold.woff2") format("woff2"),
        url("../fonts/GTWalsheim-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: GTWalsheim;
    src: url("../fonts/GTWalsheim-BoldItalic.eot");
    src: url("../fonts/GTWalsheim-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/GTWalsheim-BoldItalic.woff2") format("woff2"),
        url("../fonts/GTWalsheim-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}