.navigation {
    $elm: &;
    padding: 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;
    }

    &__list {
        width: 100%;
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        display: block;
        position: relative;
        padding: 10px 0;
        margin: 0;
    }

    &__item {
        display: inline-block;
        color: map-deep-get($colors, "white", "default");
        padding: 5px;
        white-space: nowrap;
        text-decoration: underline;
        transition: $transition-default;

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__list--sub {
        display: none;
    }
}