.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "brand", "secondary");
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    $header: &;

    @include media-breakpoint-up(md) {
        padding: 40px 0 40px auto;
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--scrolled {
        background: map-deep-get($colors, "brand", "secondary");
        margin-top: 0;

        @include media-breakpoint-up(md) {
            background: map-deep-get($colors, "brand", "secondary");
            height: map_get($header-height, "medium--scrolled");
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo,
    &__caption,
    &__switch {
        position: relative;
        z-index: 2;
    }

    &__logo {
        transition: $transition-default;
        display: block;

        svg {
            display: block;
            width: 96px;
            max-width: 100%;
            height: auto;

            @include media-breakpoint-up(md) {
                width: 166px;
            }
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 36px;
        border: 4px solid map-deep-get($colors, "brand", "secondary-light");
        border-radius: 18px;
    }

    &__switch-toggle {
        position: absolute;
        left: 26px;
        top: 2px;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: map-deep-get($colors, "brand", "primary");
        transition: $transition-default;

        &--active {
            left: 2px;
            background: #ff0000;
        }
    }

    &__off-screen {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 100vw;
        width: 100vw;
        height: 100vh;
        background: map-deep-get($colors, "brand", "secondary");
        transition: $transition-default;

        &--active {
            left: 0;
        }
    }

    &__off-screen-title {
        margin-top: 25vh;
    }

    &__navigate-back {
        display: block;

        svg {
            width: 24px;

            @include media-breakpoint-up(md) {
                width: 76px;
            }
        }

        svg path,
        svg line {
            stroke: map-deep-get($colors, "white", "default");
        }
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }
}