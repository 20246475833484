.footer {
    color: map-deep-get($colors, "white", "default");
    position: relative;
    z-index: 15;
    padding-top: 50px;
    flex: 1 1 100%;

    &__top {
        position: relative;
        color: map-deep-get($colors, "brand", "primary-dark");

        &--half-white {
            &::before {
                content: "";
                display: block;
                z-index: -2;
                position: absolute;
                top: -50px;
                bottom: 50%;
                left: 0;
                width: 100%;
                background: map-deep-get($colors, "white", "default");
            }
        }

        &::after {
            content: "";
            display: block;
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background: map-deep-get($colors, "brand", "primary");
        }
    }

    &__top-inner {
        padding: 30px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(md) {
            padding: 50px;
        }
    }

    &__top-title {
        margin: 0 0 2.2rem;
        font-size: 18px;
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            margin: 0 0 1.2rem;
            font-size: 20px;
        }

        svg {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: rem-calc(10);

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
            }

            @include media-breakpoint-up(lg) {
                width: 60px;
                height: 60px;
            }
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__address-title {
        line-height: 1.2em;
        margin: 1.2rem 0 0;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    &__social-links {
        margin-top: 1.2rem;

        svg {
            margin-right: 10px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;
        color: map-deep-get($colors, "brand", "secondary-extralight");

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        font-size: rem-calc(16);
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            padding-right: 30px;
            align-items: center;
            font-size: rem-calc(18);
        }

        &--copyright {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__reference {
        font-size: rem-calc(18);
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        display: block;
        width: 100%;
        margin-top: 1.2rem;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(22);
            display: inline-block;
            margin: 0;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}
