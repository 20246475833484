.hero {
    &__title {
        margin: -.3em 0 0;
        position: relative;
        z-index: 2;
        word-break: break-word;
    }

    &__caption {
        font-weight: 700;
        font-size: rem-calc(18);
        line-height: 3em;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "secondary-extralight");
    }

    &__image {
        position: relative;
        margin-top: -2em;
        height: 230px;
        width: 120%;
        margin-left: -20%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            margin-top: -3em;
            height: 460px;
        }

        @include media-breakpoint-up(lg) {
            height: 690px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0,0,0,.1), transparent);
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__intro {
        margin: 2em 0 3em;
    }
}
