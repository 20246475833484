.page {
    &__title {
        margin: -.3em 0 0;
        position: relative;
        z-index: 2;
        word-break: break-all;
    }

    &__caption {
        font-weight: 700;
        font-size: rem-calc(18);
        line-height: 3em;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "secondary-extralight");
    }

    &__content{
        width: 100vw;
        padding-top: 3em;
        color: map-deep-get($colors, "brand", "secondary");
        background: map-deep-get($colors, "white", "default");

        &--contact {
            width: auto;
            padding-top: 2em;
            padding-bottom: 3em;
            color: map-deep-get($colors, "white", "default");
            background: map-deep-get($colors, "brand", "secondary");
        }
    }
}